import { api, fetchProtect360List } from 'api/report';
import { filterObject } from 'utils/utils';
import qs from 'querystring';
import Pagination from '@/components/pagination';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      // 筛选条件
      filter: {
        dataTime: '',
      },
      // 列表数据
      list: [],
      // 下拉框列表
      options: {},
      // 加载
      loading: {
        list: false,
        search: false,
      },
      // 设置
      config: {
        fullWidth: document.documentElement.clientWidth,
      },
      pages: {
        isShow: false,
        total: 0,
        pageParam: {
          page: 1,
          pageSize: 20,
        },
      },
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    tableRowClassName({ rowIndex }) {
      if (rowIndex > 0 && rowIndex === this.list.length - 1) {
        return 'bgdfdfdf';
      }
    },
    // 拼接filter数据
    filterDataSet(e, event) {
      if (e === 'Search') {
        if (event == 'clickSearch') {
          this.pages.pageParam.page = 1;
          this.pages.isShow = false;
        }
        this.searchListClick(this.filter);
      }
      if (e === 'Download') {
        this.exportExcelFileClick(this.filter);
      }
    },
    // 筛选
    searchListClick(param) {
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      this.loading.list = true;
      this.loading.search = true;
      fetchProtect360List(par)
        .then((res) => {
          this.loading.list = false;
          this.loading.search = false;
          if (res.code === 200) {
            if (res.result) {
              this.pages.isShow = true;
              this.list = res.result;
              this.pages.total = res.total;
            } else {
              this.list = [];
              this.pages.total = 0;
            }
          } else {
            this.$message.error(res.message);
            this.list = [];
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error('查询失败！' + error.toString());
          this.loading.list = false;
          this.loading.search = false;
        });
    },
    // 导出
    exportExcelFileClick(param) {
      param = filterObject(param);
      let strParam = qs.stringify(param);
      window.open(`${api.DOWNLOAD_BLOCK_REPORT}?${strParam}`, '_blank');
    },
    // 更改每页显示数
    handleSizeChange(size) {
      console.log(size);
      this.pages.pageParam.pageSize = size;
      this.handleCurrentChange(1); // 更改每页显示数以后页码重置为0
    },
    // 点击数字翻页
    handleCurrentChange(currentPage) {
      this.pages.pageParam.page = currentPage;
      this.filterDataSet('Search');
    },
    // 排序监听
    sortChange(column) {
      console.log(column);
      if (column.prop !== null && column.order !== null) {
        this.filter.sorting = column.prop;
        this.filter.sortType = column.order == 'ascending' ? 'asc' : 'desc';
      } else {
        this.filter.sorting = '';
        this.filter.sortType = '';
      }
      this.filterDataSet('Search');
    },
  },
};
