<template>
  <div class="blockReport">
    <!-- <div class="main-Title bgff"><h2>Protect360</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" size="mini">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="6" :lg="5" :xl="5">
              <el-form-item label="Date:" class="mb0" label-width="50px">
                <el-date-picker
                  v-model="filter.dataTime"
                  type="date"
                  style="width: 100%;"
                  value-format="yyyy-MM-dd"
                  placeholder="Select date"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="5" :xl="5">
              <el-form-item label="Media_Source:" label-width="100px" class="mb0">
                <el-input
                  v-model="filter.mediaSource"
                  placeholder="Please enter Media Source"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="5" :xl="5">
              <el-form-item label="Site_Id:" label-width="70px" class="mb0">
                <el-input v-model="filter.siteId" placeholder="Please enter Site Id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="5" :xl="5">
              <el-form-item label="APP_ID:" label-width="70px" class="mb0">
                <el-input v-model="filter.appId" placeholder="Please enter APP ID"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
              <el-form-item class="mb0">
                <el-button
                  type="primary"
                  :loading="loading.search"
                  @click="filterDataSet('Search', 'clickSearch')"
                  >Search</el-button
                >
                <!-- <el-button type="primary" @click="filterDataSet('Download')">Download</el-button> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="protect360TableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
          :row-class-name="tableRowClassName"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="sortChange"
        >
          <!-- <el-table-column
            label="InsRejectTotall"
            prop="insRejectTotal"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
          </el-table-column> -->
          <el-table-column
            label="Media source"
            fixed
            prop="mediaSource"
            align="center"
          ></el-table-column>
          <el-table-column label="Site ID" fixed prop="siteId" align="center"></el-table-column>
          <el-table-column label="APP ID" fixed prop="appId" align="center"></el-table-column>
          <el-table-column label="date" fixed prop="dataTime" align="center"></el-table-column>
          <el-table-column label="Attributions" align="center">
            <el-table-column prop="totalInstalls" label="Total" width="120"></el-table-column>
            <el-table-column
              prop="totalFraudulentInstalls"
              label="Total fraudulent attribution"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="percentFraudulentInstalls"
              label="Fraudulent attribution %"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="totalFraudulentInstallsTrend"
              label="Fraud trend"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="blockedInstalls"
              label="Real-time blocks"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="percentBlockedInstalls"
              label="Real-time blocks %"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="detectedInstalls"
              label="Post-attribution fraud"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="detectedPercentBlockedInstalls"
              label="Post-attribution fraud %"
              width="120"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="Attribution types breakdown" align="center">
            <el-table-column
              prop="attributionTypeTotalFraudInstalls"
              label="Installs"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="attributionTypeTotalFraudReattribution"
              label="Re-attributions"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="attributionTypeTotalFraudReengagement"
              label="Re-engagements"
              width="120"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="Fake" align="center">
            <el-table-column
              prop="totalFakeInstalls"
              label="Real-time blocks"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="totalDetectedFakeInstalls"
              label="Post-attribution fraud"
              width="120"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="Hijacked" align="center">
            <el-table-column
              prop="totalHijackedInstalls"
              label="Real-time blocks"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="totalDetectedHijackedInstalls"
              label="Post-attribution fraud"
              width="120"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="Validation rules" align="center">
            <el-table-column prop="validationBots" label="Blocked" width="120"></el-table-column>
            <el-table-column
              prop="validationHijacking"
              label="Blocked attribution"
              width="120"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="Fake breakdown" align="center">
            <el-table-column
              prop="siteBlacklist"
              label="Blocked siteID denylist"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="detectedSiteBlacklist"
              label="Post-attribution siteID denylist"
              width="120"
            ></el-table-column>
            <el-table-column prop="bots" label="Blocked bots" width="120"></el-table-column>
            <el-table-column
              prop="detectedBots"
              label="Post-attribution bots"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="behavioralAnomalies"
              label="Blocked behavioral anomalies"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="detectedBehavioralAnomalies"
              label="Post-attribution behavioral anomalies"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="installStoreValidation"
              label="Blocked install validation"
              width="120"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="Hijacked breakdown" align="center">
            <el-table-column
              prop="installHijacking"
              label="Blocked hijacking"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="detectedInstallHijacking"
              label="Post-attribution hijacking"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="ctitAnomalies"
              label="Blocked CTIT anomalies"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="detectedCtitAnomalies"
              label="Post-attribution CTIT anomalies"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="clickFlood"
              label="Blocked click flooding"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="detectedClickFlood"
              label="Post-attribution click flooding"
              width="120"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="Clicks" align="center">
            <el-table-column prop="totalClicks" label="Total" width="120"></el-table-column>
            <el-table-column prop="blockedClicks" label="Blocked" width="120"></el-table-column>
            <el-table-column prop="cappedClicks" label="Capped" width="120"></el-table-column>
          </el-table-column>
          <el-table-column label="Impressions" align="center">
            <el-table-column prop="totalImpressions" label="Total" width="120"></el-table-column>
            <el-table-column
              prop="blockedImpressions"
              label="Blocked"
              width="120"
            ></el-table-column>
            <el-table-column prop="cappedImpressions" label="Capped" width="120"></el-table-column>
          </el-table-column>
          <!-- 这块有问题 -->
          <el-table-column label="Device Farm Indication" align="center">
            <el-table-column
              prop="deviceRankNewDeviceInstalls"
              label="New devices"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="deviceRankNewDeviceInstallsRate"
              label="New devices %"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="deviceRankNewDeviceLoyalUserRate"
              label="New Loyal user %"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="deviceRankLatDeviceInstalls"
              label="LAT Devices"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="deviceRankLatDeviceInstallsRate"
              label="LAT Devices %"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="deviceRankLatDeviceLoyalUserRate"
              label="Loyal user %"
              width="120"
            ></el-table-column>
          </el-table-column>
          <!-- 这块有问题 -->
          <el-table-column label="Click Flooding Indicators" align="center">
            <el-table-column
              prop="blockedAggregatedInapps"
              label="Conversion rate"
              width="120"
            ></el-table-column>
            <el-table-column prop="contribution" label="Assists %" width="120"></el-table-column>
            <el-table-column
              prop="ctitOverOneHour"
              label="CTIT > 60 minutes"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="ctitOverFiveHours"
              label="CTIT > 5 hours"
              width="120"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="In-app events" align="center">
            <el-table-column
              prop="totalAggregatedInapps"
              label="Total"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="blockedAggregatedInapps"
              label="Blocked"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="percentBlockedAggregatedInapps"
              label="Blocked %"
              width="120"
            ></el-table-column>
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          v-if="pages.isShow"
          :pageTotal="pages.total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pages.pageParam.pageSize"
          :currentPage="pages.pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import Protect360Con from '../controllers/report/protect360';
  export default {
    name: 'Protect360Con',
    ...Protect360Con,
  };
</script>

<style lang="scss" scoped></style>
